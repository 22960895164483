import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Container from './container';
import { useStaticQuery, graphql } from 'gatsby';
import Logo from '../images/logo.png'

const Header = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <StyledHeader>
      <HeaderWrapper>
        <HeaderTitle>
          <Link to="/">
            <img src={Logo} alt="logo" width="20" height="20"/> {site.siteMetadata.title}
          </Link>
        </HeaderTitle>

        <HeaderNavList>
          <HeaderNavListItem>
            <Link to="/blog"
            activeStyle={{
              color: "white",
              }}>
                Blog</Link>
          </HeaderNavListItem>

          <HeaderNavListItem>
            <Link to="/about"
            activeStyle={{
              color: "white",
              }}>
              About</Link>
          </HeaderNavListItem>

          <HeaderNavListItem>
            <Link to="/contact"
            activeStyle={{
              color: "white",
              }}>
              Contact</Link>
          </HeaderNavListItem>
        </HeaderNavList>
      </HeaderWrapper>
    </StyledHeader>
  );
};

export default Header;

const HeaderNavList = ({ children }) => {
  return (
    <StyledNav>
      <StyledNavList>{children}</StyledNavList>
    </StyledNav>
  );
};

const HeaderNavListItem = ({ children }) => {
  return <StyledNavListItem>{children}</StyledNavListItem>;
};

const StyledHeader = styled.header`
  width: 100%;
  height: 8rem;
  background-color: rgba(4, 26, 35, 1);
  box-shadow: 0 4px 15px 0 rgba(169, 171, 179, 0.05);
`;

const HeaderWrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`

  & a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    font-size: var(--size-500);
    font-weight: 700;
    color: white;

    & img {
      margin-right: 0.3rem;
    }
  }
`;

const StyledNav = styled.nav`
  position: static;
  padding: 0;
  background: transparent;
  backdrop-filter: unset;
`;

const StyledNavList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  list-style-type: none;
`;

const StyledNavListItem = styled.li`
  &:not(:last-of-type) {
    margin-right: 2rem;
  }
  @media screen and (max-width: 700px) {
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
  & a {
    padding: 0.4rem 0.2rem;
    color: inherit;
    font-size: 1rem;
    text-decoration: none;
    letter-spacing: 0.1rem;
    font-weight: 550;

    & :hover {
      background-color: rgba(255, 255, 255, 0.2) ;
      color: white;
      border-radius: 0.3rem;
    }
  }

  @media screen and (max-width: 700px) {
    & a {
      font-size: 0.65rem;
    }
  }
`;
